<template>
  <v-container :disabled="loading" style="height: 100%">
    <div
      :class="{ hide: !loading }"
      style="
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>

    <div v-if="!loading">
      <div
        v-if="invalidUrl === 'INVALID_URL'"
        style="
          margin: auto;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <img src="../assets/404-error.png" width="100" style="margin: 10px" />
        <h3 style="text-align: center">Page not found!!</h3>
      </div>
      <div
        v-else-if="invalidUrl === 'FORM_INPROGRESS'"
        class="text-center"
        style="
          margin: 24px 36px;
          padding: 16px;
          box-shadow: 2px 2px 20px rgb(0 0 0 / 10%);
          border-radius: 20px;
        "
      >
        <h2 style="margin-left: auto; margin-right: auto; margin-bottom: 16px">
          Feedback Form
        </h2>
        <div style="padding: 16px; margin-bottom: 16px">
          <v-row style="justify-content: space-between">
            <h3>{{ formTitle }}</h3>
            <h3>Due Date : {{ dueDate }}</h3>
          </v-row>
          <div v-if="cbIsNBACriteriaAttainment">
            <!-- {{response}} -->
            <div
              v-for="(item, i) in response"
              :key="i"
              style="margin-top: 16px"
            >
              <v-row style="padding: 8px; flex-direction: row; display: flex">
                <h4>Q{{ i + 1 }}.</h4>
                <p style="padding-left: 8px">{{ item.question }}</p>
              </v-row>
              <v-row style="margin-top: 0px; padding-left: 16px">
                <v-radio-group row v-model="item.answer">
                  <!-- {{ item }} -->
                  <v-radio
                    v-for="i in item.options"
                    :label="i"
                    :value="i"
                    :key="i"
                  ></v-radio>
                </v-radio-group>
              </v-row>
            </div>
          </div>
          <div v-else>
            <div
              v-for="(item, i) in response"
              :key="i"
              style="margin-top: 16px"
            >
              <v-row style="padding: 8px; flex-direction: row; display: flex">
                <h4>Q{{ i + 1 }}.</h4>
                <p style="padding-left: 8px">{{ item.question }}</p>
              </v-row>
              <v-row
                v-if="item.questionType === 'Multiple Choice Question'"
                style="margin-top: 0px; padding-left: 16px"
              >
                <v-radio-group row v-model="item.answer">
                  <v-radio
                    v-for="(opt, i) in item.options"
                    :label="opt.option ? opt.option : opt"
                    :value="opt"
                    :key="i"
                  ></v-radio>
                </v-radio-group>
              </v-row>
              <v-row
                v-else-if="item.questionType === 'Short Answer'"
                style="margin-top: 0px; padding-left: 16px"
              >
                <inputContainer>
                  <v-text-field
                    background-color="#E7E7E7FF"
                    v-model="item.answer"
                    solo
                    flat
                    style="width: 100%"
                  ></v-text-field>
                </inputContainer>
              </v-row>
              <v-row
                v-else-if="item.questionType === 'Rating'"
                style="margin-top: 0px; padding-left: 16px"
              >
                <v-rating
                  class="text-center"
                  v-model="item.answer"
                  background-color="warning lighten-1"
                  color="warning"
                  empty-icon="mdi-star-outline"
                  full-icon="mdi-star"
                  half-icon="mdi-star-half-full"
                  half-increments
                  hover
                  length="5"
                  size="44"
                  value="5"
                ></v-rating>
              </v-row>
            </div>
          </div>

          <v-row style="margin-top: 16px">
            <v-btn
              style="
                background-color: #ff4f1f;
                color: #fff;
                text-transform: capitalize;
                margin-left: 16px;
              "
              @click="submitForm"
              >Submit Form</v-btn
            >
          </v-row>
        </div>
      </div>
      <div
        v-else-if="invalidUrl === 'FORM_SUBMITTED_SUCCESS'"
        style="
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <img src="../assets/checked.png" width="100" style="margin: 10px" />
        <h3>Form submitted Successfully!!</h3>
      </div>
    </div>
  </v-container>
</template>

<style>
.hide {
  display: none !important;
}
</style>
<script>
import networkManager from "../NetworkManager/index";
import showStatus from "../NetworkManager/showStatus";

export default {
  name: "HelloWorld",

  data: () => ({
    formUniqueUrlId: "",
    formId: "",
    loading: true,
    formTitle: "",
    dueDate: "",
    cbIsNBACriteriaAttainment: "",
    selectedAns: "",
    questionList: [],
    qOptions: [],
    response: [],
    invalidUrl: "INVALID_URL",
    formType: "",
  }),

  created() {
    this.getFormId();
  },
  methods: {
    getFormId() {
      const url = window.location.href;
      const lastParam = url.split("/").slice(-1)[0];
      if (lastParam === "") {
        this.invalidUrl = "INVALID_URL";
      }

      this.formUniqueUrlId = lastParam;
      this.invalidUrl = "FORM_INPROGRESS";
      this.getFormDetails();
    },
    async getFormDetails() {
      try {
        const res = await networkManager.getRequest(
          "/feedbackWithNBAAttainment/getFeedbackByFormUrl",
          {
            feedbackId: this.formUniqueUrlId,
          }
        );
        this.formType = res.feedback[0].formType;
        const data = await res.feedback;
        this.formId = data[0]._id;
        this.formTitle = data[0].formTitle;
        this.dueDate = data[0].endDate;
        // if (data[0].formType === 'Course Exit Survey') {
        //   console.log("hii")
        //   this.invalidUrl = 'INVALID_URL'
        // }
        this.cbIsNBACriteriaAttainment = data[0].isNBACriteriaAttainment;
        if (data[0].isNBACriteriaAttainment) {
          data[0].questions.map((item, index) => {
            console.log(index);
            // console.log("item", item);
            this.response.push({
              question: item.question,
              options: data[0].nbaAttainmentLevels,
            });
          });
        } else {
          this.response = data[0].questions;
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.invalidUrl = "INVALID_URL";
        showStatus(err, 6000, "error", this);
      }
    },
    async submitForm() {
      let flag = false;
      this.response.map((item) => {
        if (!item.answer) {
          flag = true;
        }
      });
      if (!flag) {
        //Submit Feedback data
        this.response.map((item) => {
          if (!this.cbIsNBACriteriaAttainment) {
            if (item.questionType === "Multiple Choice Question") {
              delete item.options;
            }
          } else {
            delete item.options;
          }
        });
        try {
          const res = await networkManager.postRequest(
            "/feedbackWithNBAAttainment/saveResponse",
            {
              feedbackFormId: this.formId,
              studentResponse: this.response,
            }
          );
          await res;
          this.invalidUrl = "FORM_SUBMITTED_SUCCESS";
          alert("Form submitted Successfully!!");
          showStatus("Form submitted Successfully!!", 6000, "success", this);
        } catch (err) {
          showStatus(err, 6000, "error", this);
        }
      } else {
        alert("Fill blank fields");
        showStatus("Fill blank fields", 6000, "error", this);
      }
    },
  },
};
</script>
