import axios from 'axios'

// export const serverUrl = 'http://ec2-54-209-157-112.compute-1.amazonaws.com:3010'
// export const socketUrl = 'http://ec2-54-209-157-112.compute-1.amazonaws.com:8090'
// export const socketUrl = 'http://localhost:8090'
// export const serverUrl = 'http://localhost:3000'

export const serverUrl = process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PORT
// export const socketUrl = process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_SOCKET_PORT
export const spacesUrl = `https://${process.env.VUE_APP_SPACES_NAME}.${process.env.VUE_APP_SPACES_URL}`

export default {

  getRequest: async (apiName, data) => {
    const res = await axios.get(serverUrl + apiName, { params: data })
    window.console.log(res.data)
    return res.data
  },

  postRequest: async (apiName, data) => {
    const res = await axios.post(serverUrl + apiName, data)
    window.console.log(res.data)
    return res.data
  },

  putRequest: async (apiName, data) => {
    const res = await axios.put(serverUrl + apiName, data)
    window.console.log(res.data)
    return res.data
  },

  deleteRequest: async (apiName, data) => {
    const res = await axios.delete(serverUrl + apiName, { params: data })
    window.console.log(res.data)
    return res
  },

  setHeader: function (data) {
    axios.defaults.headers = data
  }
}

// function getHeaders() {
//   //add custom headers here
//   return {
//     uId: ""
//   }
// }
